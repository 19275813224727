import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NoAuthGuard} from "./core/auth/guards/noAuth.guard";

// Extra Config
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./core/login/login.module').then(m => m.LoginModule)},
  {path: 'recovery/:id', loadChildren: () => import('./modules/recovery/recovery.module').then(m => m.RecoveryModule)},
  {path: 'active/:id', loadChildren: () => import('./modules/active/active.module').then(m => m.ActiveModule)},
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () => import('./layout/main/main.module').then(m => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
